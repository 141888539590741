import './App.css';
import Header from './Components/header/header'
import Footer from './Components/footer/footer'
import Home from './Pages/home/home'
import Career from './Pages/careers/career'
import About from './Pages/about/about'
import Uiux from './Pages/uiux/uiux'
import WebDevelopment from './Pages/webDevelopment/webDevelopment';
import Mobile from './Pages/mobileDevelopment/mobile';
import Digital from './Pages/digitalMarketting/digital';
import CareerForm from './Components/career_form/career_form'
import Contact from './Pages/contact/contact'
import TAC from './Components/terms_and_conditions/tac'
import PP from './Components/privacy_policy/PP'
import Cookies from './Components/cookies_policy/cookies'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useState } from 'react';

function App() {
    const [selectedName, setSelectedName] = useState()
    return (
        <Router>
            <Header />
            <Switch>
                <Route path="/" exact
                    component={Home} />
                <Route path="/career" exact>
                    <Career setSelectedName={setSelectedName}/>
                </Route>
                <Route path="/about" exact
                    component={About} />
                <Route path="/uiux" exact
                    component={Uiux} />
                <Route path="/webdevelopment" exact
                    component={WebDevelopment} />
                <Route path="/mobiledevelopment" exact
                    component={Mobile} />
                <Route path="/digitalmarketing" exact
                    component={Digital} />
                <Route path="/career/form" exact>
                    <CareerForm selectedName={selectedName}/>
                </Route>
                <Route path="/Contact" exact
                    component={Contact} />
                <Route path="/termsAndConditions" exact
                    component={TAC} />
                <Route path="/privacyPolicy" exact
                    component={PP} />
                <Route path="/cookies" exact
                    component={Cookies} />
            </Switch>
            <Footer />
        </Router>
    );
}

export default App;
