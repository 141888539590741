import React, { useState } from "react";
import "./menu.css";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// importing svg images seperately because of some react bundling issue
import facebook_icon from '../../images/social-media-icons/icons8-facebook-circled.svg'
import twitter_icon from '../../images/social-media-icons/icons8-twitter.svg'
import linkedin_icon from '../../images/social-media-icons/icons8-linkedin-2.svg'
import youtube_icon from '../../images/social-media-icons/icons8-youtube-logo.svg'
import printest_icon from '../../images/social-media-icons/icons8-pinterest.svg'

function Menu() {
  //social media navigation
  const fb = () =>
    window.open("https://www.facebook.com/Glocalsense-416084498961633/");
  const twitter = () => window.open("https://twitter.com/glocalsense");
  const linked = () =>
    window.open("https://www.linkedin.com/company/glocalsense/about/");
  const youtube = () =>
    window.open("https://www.youtube.com/channel/UCjRC-f4tGduHlRPrE79NCtg");
  const printerest = () =>
    window.open(" https://in.pinterest.com/glocalsense/");

  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const closeMenu = () => {
    setNavbarOpen(!navbarOpen);
  };

  // Routing done here

  const history = useHistory();
  const route_to_home = () => history.push("/");
  const route_to_about = () => history.push("/about");
  const route_to_careers = () => history.push("/career");
  const route_to_contact = () => history.push("/contact");
  const route_to_uiux = () => history.push("/uiux");
  const route_to_webdevelopment = () => history.push("/webdevelopment");
  const route_to_mobiledevelopment = () => history.push("/mobiledevelopment");
  const route_to_digitalmarketing = () => history.push("/digitalmarketing");
  const route_to_blog = () => window.open("https://blog.glocalsense.org");

  return (
    <div>
      <div
        className={`menuContainer ${navbarOpen ? "close" : ""}`}
        onClick={handleToggle}
      >
        {navbarOpen ? "Close" : "Menu"}{" "}
      </div>

      <div
        className={`main_menu_container ${navbarOpen ? "show_menu" : ""}`}
        onClick={closeMenu}
      >
        {navbarOpen ? (
          <div className="menu_sub_container">
            <div className="left_column_container">
              {/* menu items container */}

              <div className="menu_container">
                <div className="menu_item_container">
                  <div className="menu_item" onClick={route_to_home}>
                    <HashLink smooth to={"#top"} className="HashLink">
                      Home
                    </HashLink>
                    <div className="hoverbox"></div>
                  </div>
                  <div className="menu_item" onClick={route_to_about}>
                    <HashLink to="#top" className="HashLink">
                      About Us
                    </HashLink>
                    <div className="hoverbox"></div>
                  </div>
                  <div className="menu_item">
                    <HashLink smooth to={"/#services"} className="HashLink">
                      Services
                    </HashLink>
                    <div className="hoverbox"></div>
                  </div>
                  <div className="menu_item" onClick={route_to_contact}>
                    <HashLink to="#top" className="HashLink">
                      Contact Us
                    </HashLink>
                    <div className="hoverbox"></div>
                  </div>
                  <div className="menu_item" onClick={route_to_careers}>
                    <HashLink to="#top" className="HashLink">
                      Careers
                    </HashLink>
                    <div className="hoverbox"></div>
                  </div>
                  <div className="menu_item" onClick={route_to_blog}>
                    Blog
                    <div className="hoverbox"></div>
                  </div>
                </div>
              </div>

              {/* menu items container ends */}

              <div className="menu_inner_container_content">
                <div className="menu_footer_inner_container_heading">
                  Marketing
                </div>
                <div
                  className="menu_content_text"
                  onClick={route_to_digitalmarketing}
                >
                  <HashLink smooth to={"#top"} className="HashLink">
                    Search Engine Optimization (SEO)
                  </HashLink>
                </div>
                <div
                  className="menu_content_text"
                  onClick={route_to_digitalmarketing}
                >
                  <HashLink smooth to={"#top"} className="HashLink">
                    Search Engine Marketing (SEM)
                  </HashLink>
                </div>
                <div
                  className="menu_content_text"
                  onClick={route_to_digitalmarketing}
                >
                  <HashLink smooth to={"#top"} className="HashLink">
                    Social Media Marketing (SMM)
                  </HashLink>
                </div>
                <div
                  className="menu_content_text"
                  onClick={route_to_digitalmarketing}
                >
                  <HashLink smooth to={"#top"} className="HashLink">
                    Email Marketing
                  </HashLink>
                </div>
              </div>

              <div className="menu_inner_container_content">
                <div className="menu_footer_inner_container_heading">
                  Development
                </div>
                <div
                  className="menu_content_text"
                  onClick={route_to_webdevelopment}
                >
                  <HashLink smooth to={"#top"} className="HashLink">
                    E-Commerce Web Development
                  </HashLink>
                </div>
                <div
                  className="menu_content_text"
                  onClick={route_to_webdevelopment}
                >
                  <HashLink smooth to={"#top"} className="HashLink">
                    WordPress Web Development
                  </HashLink>
                </div>
                <div
                  className="menu_content_text"
                  onClick={route_to_mobiledevelopment}
                >
                  <HashLink smooth to={"#top"} className="HashLink">
                    Application Development
                  </HashLink>
                </div>
                <div className="menu_content_text" onClick={route_to_uiux}>
                  <HashLink smooth to={"#top"} className="HashLink">
                    UI/UX Solutions
                  </HashLink>
                </div>
                <div className="menu_footer_inner_container_heading1">
                  Social
                </div>
                <div className="menu_image">
                  <img src={facebook_icon} alt="" onClick={fb} />
                  <img src={twitter_icon} alt="" onClick={twitter} />
                  <img src={linkedin_icon} alt="" onClick={linked} />
                  <img
                    src={youtube_icon}
                    alt=""
                    onClick={youtube}
                  />
                  <img
                    src={printest_icon}
                    alt=""
                    onClick={printerest}
                  />
                </div>
              </div>
            </div>

            <div className="right_column_container">
              <div className="estimate_container">
                <div className="message_us_button">
                  <HashLink smooth to={"/#contact"} className="HashLink">
                    Message Us
                  </HashLink>
                </div>
              </div>
              {/* <div className="contact_container">
                                <div className="contact_item_container">
                                    <img src={Phone} alt=""></img>
                                    <div className="contact_content">9876543210</div>
                                </div>
                                <div className="contact_item_container">
                                    <img src={Mail} alt=""></img>
                                    <div className="contact_content">info@glocalsense.org</div>
                                </div>
                                <div className="contact_item_container">
                                    <img src={Map_Pin} alt=""></img>
                                    <div className="contact_content">#01, Sample Steer, Office address here, Chennai, Tamil-Nadu, India</div>
                                </div>
                            </div> */}{" "}
            </div>
          </div>
        ) : (
          <div></div>
        )}{" "}
      </div>
    </div>
  );
}

export default Menu;
