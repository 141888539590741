import "./career_form.css";
import { useForm } from "react-hook-form";
import Recaptcha from "react-recaptcha";
import emailjs from "emailjs-com";
// import Career from "../../Pages/careers/career";

// declare global {
//     interface Window {
//         Email: any;
//     }
// }

// let Email = window.Email;

function CareerForm({ selectedName }: { selectedName: any }) {
  var isverified: boolean = false;
  var current_name = sessionStorage.getItem('career')

  type Inputs = {
    name: string;
    email: string;
    phone_number: string;
    work_experience: number;
    desigination: string;
    Previous_Organization: string;
    message: string;
    post: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = (e: Event) => {
    // e.preventDefault();
    console.log(e);
    
    if (sessionStorage.getItem('isverified') === "true") {
      console.log("isverified", "true");

      emailjs
        .sendForm(
          "service_a14xaya",
          "template_815qw04",
          "#careerForm",
          "user_elTEvMlSv9RucbDjIdmyf"
        )
        .then(
          (result) => {
            console.log(result.text, "result");
            if (result.text === "OK") {
              window.alert("The form as been submitted successfully");
              window.location.reload();
              window.scrollTo(0, 0);
              sessionStorage.removeItem('isverified');
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      alert("Please verify that you are a human");
    }
  };

  const callback = () => {
    console.log("recaptcha working");
  };

  const verifycallback = (response: any) => {
    if (response) {
      console.log("response working");
      // isverified = true;
      sessionStorage.setItem('isverified', "true");
    }
  };

  return (
    <div className="career_form_body">
      <div className="career_form_title">Apply For</div>
      <div className="career_form_title">{current_name}&nbsp;</div>
      <div className="career_form_title">CAREER FORM</div>
      <div className="career_form_container">
        <div className="form_container_warp_career">
          <form id="careerForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="input_container">
              <label className="label" htmlFor="name">
                Full Name
              </label>
              <input
                id="name"
                type="text"
                className="form__field"
                {...register("name", { required: true })}
                name="name"
              />
              {errors.name && <span id="error">This field is required</span>}
            </div>
            <div className="input_container">
              <label className="label" htmlFor="email">
                E-Mail Address
              </label>
              <input
                id="email"
                type="email"
                className="form__field"
                {...register("email", { required: true })}
                name="email"
              />
              {errors.email && <span id="error">This field is required</span>}
            </div>
            <div className="input_container">
              <label className="label" htmlFor="phone_number">
                Mobile Number
              </label>
              <input
                id="phone_number"
                type="text"
                className="form__field"
                {...register("phone_number", { required: true })}
                name="phone_number"
              />
              {errors.phone_number && <span id="error">This field is required</span>}
            </div>
            <div className="input_radio_container">
              <input
                value="Internship"
                id="internship"
                type="radio"
                {...register("post",{ required: true })}
                name="post"
              />
              <label className="radio_label" htmlFor="internship">
                Internship
              </label>
              <input
                type="radio"
                id="Part-Time"
                value="Part-Time"
                {...register("post",{ required: true })}
                name="post"
              />
              <label className="radio_label" htmlFor="Part-Time">
                Part-Time
              </label>
              <input
                type="radio"
                id="Full-Time"
                value="Full-Time"
                {...register("post",{ required: true })}
                name="post"
              />
              <label className="radio_label" htmlFor="Full-Time">
                Full-Time
              </label>
            </div>
            <div>
              <div className="input_container">
                <label className="label" htmlFor="phone_number">
                  Work Experience
                </label>
                <input
                  id="phone_number"
                  type="text"
                  className="form__field"
                  {...register("work_experience", { required: true })}
                  name="work_experience"
                />
                {errors.work_experience && <span id="error">This field is required</span>}
              </div>

              <div className="input_container">
                <label className="label" htmlFor="phone_number">
                  Previous Organization
                </label>
                <input
                  id="phone_number"
                  type="text"
                  className="form__field"
                  {...register("Previous_Organization", { required: true })}
                  name="Previous_Organization"
                />
                {errors.Previous_Organization && <span id="error">This field is required</span>}
              </div>
            </div>

            <div className="input_container">
              <label className="label" htmlFor="phone_number">
                Desigination
              </label>
              <input
                id="phone_number"
                type="text"
                className="form__field"
                {...register("desigination", { required: true })}
                name="desigination"
              />
              {errors.desigination && <span id="error">This field is required</span>}
            </div>
            <div className="input_container">
              <label className="label" htmlFor="message">
                Enter Your Message
              </label>
              <textarea
                id="message"
                className="text_area"
                {...register("message",{ required: true })}
                name="message"
              />
              {errors.desigination && <span id="error">This field is required</span>}
            </div>
            <div className="recapt">
              <Recaptcha
                sitekey="6Lch-jwdAAAAAPFKnWDTb4uhUdlzb1aZ3EGyEwD5"
                render="explicit"
                onloadCallback={callback}
                verifyCallback={verifycallback}
              />
            </div>
            <div className="button_position">
              <button className="submit" type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CareerForm;
