import React from 'react';
import './uiux.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

function Uiux() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    })
    return (
        <div className="uiux_main_container">
            <div className="uiux_title_container" data-aos="fade-in">
                <div className="uiux_title_sub_container">

                    {/* <img src={UIUX} alt=""></img> */}
                    <div className="uiux_title">UI/UX Solutions</div>
                </div>
            </div>
            <div className="ux_middle_content_container">
                <img src="images/ui-ux/uiux_content.svg" alt="" data-aos="fade-in"></img>
                <div className="ux_middle_container_content" data-aos="fade-in">
                    <div className="uiux_content">
                    UI refers to User Interface and UX refers to the User Experience – the two most important aspects to be considered while building a website. Web development mainly focuses on the look and feel of a website and this includes the user interface, navigation, the color, the font, images used, the pages, and the content.
                    Having a better UI/UX for your website is important because everyone who visits your website should enjoy your website. A visually pleasing website with clear navigation options impresses your customer in a better way.
                    Looking for the right web development solutions? We are here to provide you with the right web solutions that suit your business.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Uiux;
