// import React, { useState } from 'react';
import Menu from '../menu/menu';
import "./header.css"
import { useHistory } from 'react-router';
// import "../../Images/logo"

// importing svg images seperately because of some react bundling issue
import Logo from '../../images/logo/glocalsense logo Transparent.svg'


function Header() {

  const history = useHistory();
  const route_to_home = () => history.push('/');

  return (
    <header className="header-container">
      <Menu></Menu>
      <div className="block"></div>
      <div id = "logo" className="logo" onClick={route_to_home}>
        <img src={Logo} alt="" />
      </div>
    </header>
  );
}

export default Header;
