import './digital.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

function Digital() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    })

    return (
        <div className="digital_main_container" >
            <div className="digital_title_container" data-aos="fade-in">
                <div className="digital_title_sub_container">
                    {/* <img src={digital} alt=""></img> */}
                    <div className="digital_title">Digital Marketing</div>
                </div>
            </div>
            <div className="digital_middle_content_container">
                <img src="images/digital-marketing/digital_content.svg" alt="" data-aos="fade-in"></img>
                <div className="middle_container_content">
                    <section id="seo">

                        <div className="digital_content_container" data-aos="fade-in">
                            <div className="digital_content_title">Search Engine Optimization (SEO)</div>
                            <div className="digital_content">
                                SEO is the process of driving more traffic to your website via organic search engine results, thereby which the quantity and quality of the web traffic are increased. Generally, the page that tops the list in the search engine results receive more visitors; these visitors could then be converted into customers.
                                Proper implementation of SEO (Search Engine Optimisation)in your website helps you to attract visitors across the globe. The more the people visit your website, the more valuable leads you can attract.
                                Optimizing your website content is more important when implementing SEO. Your website content should simple, to the point, and most importantly, relevant. But then how to implement it effectively? We help you keep your website updated andimplement the right SEO strategy that suits your business& your target market.
                            </div>
                        </div>
                    </section>
                    <section id="sem">

                        <div className="digital_content_container" data-aos="fade-in">
                            <div className="digital_content_title">Search Engine Marketing (SEM)</div>
                            <div className="digital_content">
                                Implementing SEM in the right way helps you to engage your audience across various search engines and various other display platforms. SEM is all about the process that involves the promotion of your website by increasing the visibility in SERPs. Thisis mainly done through paid marketing strategies.
                                Maintaining the visibility of your website on the search engines when someone actively searches using keywords related to your business is a magnet to attract active hot leads. Search Engine Marketing is an effective way of using paid ads & making your presence felt in the realms of Search Engines.
                            </div>
                        </div>
                    </section>
                    <section id="Email">

                        <div className="digital_content_container" data-aos="fade-in">
                            <div className="digital_content_title">Email Marketing</div>
                            <div className="digital_content">
                                It is defined as the act of sending commercial messages using email. Typically these commercial messages are sent to a group of people. Every email that is sent to a potential customer can be considered as email marketing.
                                Email Marketing isimportantfor any marketing strategy. It is the most cost-effective and affordable form of marketing that could be used to promote your products/services, sell it to your customers, communicate with them effectively, and reach your business goals.
                                We at Glocalsense help you in sending emails to prospective clients and customers. Our experts, use technologies such as MailChimp, Drip, Zoho,etc. to build up mailing lists and help you interact with your audience, and at the same time promote your brand and increase your sales.
                            </div>
                        </div>
                    </section>
                    <section id="smm">

                        <div className="digital_content_container" data-aos="fade-in">
                            <div className="digital_content_title">Social Media Marketing (SMM)</div>
                            <div className="digital_content">
                                SMM is one of the verticals in digital marketing and refers to the process of gaining website traffic through digital platforms and social mediums. Having a strong web presence helps you to attract new customers and retains the existing customers. We provide the best SMM strategies that suit your business perfectly.
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Digital;