import React from 'react';
import './footer.css';
import { useHistory } from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';

// importing svg images seperately because of some react bundling issue
import facebook_icon from '../../images/social-media-icons/icons8-facebook-circled.svg'
import twitter_icon from '../../images/social-media-icons/icons8-twitter.svg'
import linkedin_icon from '../../images/social-media-icons/icons8-linkedin-2.svg'
import youtube_icon from '../../images/social-media-icons/icons8-youtube-logo.svg'
import printest_icon from '../../images/social-media-icons/icons8-pinterest.svg'
import phone_icon from '../../images/contact-icon/phone.png'
import mail_icon from '../../images/contact-icon/mail.png'
import map_icon from '../../images/contact-icon/map.png'



function Footer() {
    const fb = () => window.open("https://www.facebook.com/Glocalsense-416084498961633/");
    const twitter = () => window.open("https://twitter.com/glocalsense");
    const linked = () => window.open("https://www.linkedin.com/company/glocalsense/about/");
    const youtube = () => window.open("https://www.youtube.com/channel/UCjRC-f4tGduHlRPrE79NCtg");
    const printerest = () => window.open("https://in.pinterest.com/glocalsense/");
    const email = () => window.open("mailto:info@glocalsense.org")
    const phone = () => window.open("tel:917010725840")
    const address = () => window.open("https://goo.gl/maps/Z3VktsEZRsZEx54q7")
    const history = useHistory();
    const route_to_uiux = () => history.push('/uiux');
    const route_to_webdevelopment = () => history.push('/webdevelopment');
    const route_to_mobiledevelopment = () => history.push('/mobiledevelopment');
    const route_to_digitalmarketing = () => history.push('/digitalmarketing');
    const route_to_termsandconditions = () => {
        window.scrollTo(0, 0);
        history.push('/termsAndConditions');
    }
    const route_to_privacypolicy = () => {
        window.scrollTo(0, 0);
        history.push('/privacyPolicy');
    }

    return (
        <footer>
            <div className="up">
                <div className="footer_up_contaoner_wrap">
                    <div className="footer_inner_container">
                        <div className="footer_inner_container_heading">Marketing</div>
                        <div className="footer_inner_container_content">
                            <div className="footer_content_text" onClick={route_to_digitalmarketing}>
                                <HashLink smooth
                                        to={"#top"}
                                        className="HashLink">
                                        Search Engine Optimization (SEO)
                                </HashLink>
                            </div>
                            <div className="footer_content_text" onClick={route_to_digitalmarketing}>
                                <HashLink smooth
                                        to={"#top"}
                                        className="HashLink">
                                        Search Engine Marketing (SEM)
                                </HashLink>
                                </div>
                            <div className="footer_content_text"onClick={route_to_digitalmarketing}>
                                <HashLink smooth
                                        to={"#top"}
                                        className="HashLink">
                                        Social Media Marketing (SMM)
                                </HashLink>
                            </div>
                            <div className="footer_content_text"onClick={route_to_digitalmarketing}>
                                <HashLink smooth
                                        to={"#top"}
                                        className="HashLink">
                                        Email Marketing
                                </HashLink>
                            </div>
                        </div>
                    </div>
                    <div className="footer_inner_container">
                        <div className="footer_inner_container_heading">Development</div>
                        <div className="footer_inner_container_content">
                        <div className="footer_content_text" onClick={route_to_webdevelopment}>
                                <HashLink smooth
                                        to={"#top"}
                                        className="HashLink">
                                        E-Commerce Web Development
                                </HashLink>
                            </div>
                            <div className="footer_content_text" onClick={route_to_webdevelopment}>
                                <HashLink smooth
                                        to={"#top"}
                                        className="HashLink">
                                        WordPress Web Development
                                </HashLink>
                            </div>
                            <div className="footer_content_text" onClick={route_to_mobiledevelopment}>
                                <HashLink smooth
                                        to={"#top"}
                                        className="HashLink">
                                        Application Development
                                </HashLink>
                            </div>
                            <div className="footer_content_text" onClick={route_to_uiux}>
                                <HashLink smooth
                                        to={"#top"}
                                        className="HashLink">
                                        UI/UX Solutions
                                </HashLink>
                            </div>
                        </div>
                    </div>
                    <div className="footer_inner_container">
                        <div className="footer_inner_container_heading">Contact us</div>
                        <div className="footer_inner_container_content">
                            <div className="footer_contact_component">
                                <img src={phone_icon} alt=""/>
                                <div className="email" onClick={phone}>7010725840</div>
                            </div>
                            <div className="footer_contact_component">
                                <img src={mail_icon} alt=""/>
                                <div className="email" onClick={email}>info@glocalsense.org</div>
                            </div>
                            <div className="footer_contact_component">
                                <img src={map_icon} alt=""/>
                                <div className="email" onClick={address}>158, 2nd floor - Guleacha Towers, Arcot road, opp to forum Vijaya mall, Vadapalani, Chennai, 600026.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="down">
                <div className="footer_icon">
                    <img src={facebook_icon} alt="" onClick={fb} />
                    <img src={twitter_icon} alt="" onClick={twitter}/>
                    <img src={linkedin_icon} alt="" onClick={linked}/>
                    <img src={youtube_icon} alt="" onClick={youtube}/>
                    <img src={printest_icon} alt="" onClick={printerest}/>
                </div>
                <div className="copyright">
                    <span onClick={route_to_termsandconditions}>Terms and Conditions &nbsp;</span> | <span onClick={route_to_privacypolicy}> &nbsp; Privacy Policy</span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
