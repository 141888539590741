import "./home.css";
import { useHistory } from "react-router-dom";
import { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useForm } from "react-hook-form";
import { useLottie } from "lottie-react";
import Arrow from "../../Lottie/arrow.json";
import { HashLink } from "react-router-hash-link";
import Recaptcha from "react-recaptcha";
import CookieConsent from "react-cookie-consent";
import emailjs from "emailjs-com";

// importing svg images seperately because of some react bundling issue
// import { ReactComponent as logo} from '../../images/logo/glocalsense logo Transparent.svg'

function Home() {
  const form: any = useRef();

  // sessionStorage.setItem("accepted", "false");

  var isverified: boolean = false;

  //initializing animation here

  useEffect(() => {
    AOS.init({ duration: 2000, disable: "mobile" });
  });

  // Routing done here

  const history = useHistory();
  const route_to_ui = () => history.push("/uiux");
  const route_to_cookies = () => history.push("/cookies");
  const route_to_web = () => history.push("/webdevelopment");
  const route_to_mobiledevelopment = () => {
    window.scrollTo(0, 0);
    history.push("/mobiledevelopment");
  };
  const route_to_digitalmarketing = () => history.push("/digitalmarketing");

  // const hide_cookies = () => {
  //     let popup: HTMLElement = document.getElementsByClassName("cookie-consent-container")[0] as HTMLElement;
  //     popup.style.display = "none"
  //     sessionStorage.setItem("accepted", "false");
  // };

  
// EMAIL HANDLER

  type Inputs = {
    name: string;
    email: string;
    phone_number: number;
    message: string;
  };

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const onSubmit = (e: Event) => {
    // e.preventDefault();
    if (sessionStorage.getItem('isverified') === "true") {
        console.log("isverified","true");
        
      emailjs
        .sendForm(
          "service_a14xaya",
          "template_rstupf1",
          "#contactForm",
          "user_elTEvMlSv9RucbDjIdmyf"
        )
        .then(
          (result) => {
            console.log(result.text,"result");
            if(result.text === "OK")
            {
                window.alert("The form as been submitted successfully")
                window.location.reload();
                window.scrollTo(0, 0)
                sessionStorage.removeItem('isverified');
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      alert("Please verify that you are a human");
    }
  };

  // ARROW LOTTIE HANDLED HERE
  const ArrowLottie = () => {
    const options = {
      animationData: Arrow,
      loop: true,
      autoplay: true,
    };
    const { View } = useLottie(options);
    return View;
  };

  // RECAPTCHA CALLBACK FUNCTIONS
  const callback = () => {
    console.log("recaptcha working");
  };

  // RECAPTCHA CALLBACK FUNCTIONS
  const verifycallback = (response: any) => {
    if (response) {
      console.log("recap", response);
      console.log("response working");
      // isverified = true;
      sessionStorage.setItem('isverified', "true");
    }
  };

  // Design starts here

  return (
    <div>
      {/* Home section starts */}
      <section className="home-container">
        {/* <div className="heading_container" data-aos="zoom-out">
                    Websites promote you 24 x 7 and no employee will do that
                </div>
                <div className="home_email_container" data-aos="zoom-out">
                    <img src="/black_mail.png" alt="" />
                    <div className="email">info@glocalsense.org</div>
                </div> */}

        <Carousel
          autoPlay={true}
          showIndicators={false}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
          interval={3000}
        >
          <div className="main_image_container">
            <img src="images/home/Analytics.jpg" alt="" />
          </div>
          <div className="main_image_container">
            <img src="images/home/App Development.jpg" alt="" />
          </div>
          <div className="main_image_container">
            <img src="images/home/Digital Marketing.jpg" alt="" />
          </div>
          <div className="main_image_container">
            <img src="images/home/Integration.jpg" alt="" />
          </div>
          <div className="main_image_container">
            <img src="images/home/Management Consulting.jpg" alt="" />
          </div>
          <div className="main_image_container">
            <img src="images/home/Website Development.jpg" alt="" />
          </div>
        </Carousel>
      </section>

      {/* Home section ends */}

      {/* service section starts */}
      <section id="services" className="service_container">
        <div className="heading_container" data-aos="zoom-out">
          <div className="service_title">Services</div>
          {/* <div className="service_content">We provide best IT Solutions to all industry which needs IT Support. We do consultancy, Development & Outsourcing</div> */}
        </div>
        <div className="service_box_container" data-aos="fade-up">
          {/* box 1 */}

          <div className="box" onClick={route_to_ui} data-aos="flip-left">
            <HashLink to="#top" className="HashLink">
              <div className="icon">
                <img src="images/services-card/uiux.svg" alt="" />
              </div>
              <div className="box_title">UI/UX Solutions</div>
              <div className="box_content">
                High level UI Functional designs for our Clients better
                understanding and experience.
              </div>
            </HashLink>
          </div>

          {/* box 2 */}
          <div className="box" onClick={route_to_web} data-aos="flip-right">
            <HashLink to="#top" className="HashLink">
              <div className="icon">
                <img src="images/services-card/web.svg" alt="" />
              </div>
              <div className="box_title">Web Development</div>
              <div className="box_content">
                We provide best website designing for all marketing and
                manufacturing industry.
              </div>
            </HashLink>
          </div>

          {/* box 3 */}
          <div
            className="box"
            onClick={route_to_mobiledevelopment}
            data-aos="flip-left"
          >
            <div className="icon">
              <img src="images/services-card/app.svg" alt="" />
            </div>
            <div className="box_title">Application Development</div>
            <div className="box_content">
              We develop native iPhone apps, android apps, cross platform apps
              and web app.
            </div>
          </div>

          {/* box 4 */}
          <div
            className="box"
            onClick={route_to_digitalmarketing}
            data-aos="flip-right"
          >
            <HashLink to="#top" className="HashLink">
              <div className="icon">
                <img src="images/services-card/digital.svg" alt="" />
              </div>
              <div className="box_title">Digital Marketing</div>
              <div className="box_content">
                We offer a complete Digital Marketing Services that
                help you to amplify your business results.
              </div>
            </HashLink>
          </div>
        </div>
      </section>
      {/* service section ends */}

      {/* Contact_us section Starts */}
      <section id="contact" className="contact_us_container">
        <div className="container_wrap">
          <div className="left_column">
            <div className="contact_us_title">Get in touch with us !</div>
            <div className="contact_us_subheading">
              Want a free quote to estimate the price of your project ?
            </div>
            <div className="contact_us_content">
              Fill this form and we will get back to you on your requirements if
              feasible
            </div>
            <div className="contact_us_content">Or</div>
            <div className="contact_us_content">
              Reach out to us directly using Email
            </div>
            <div className="contact_us_content" id="bold">info@glocalsense.org</div>
            <div id="lottie-arrow">
              <ArrowLottie />
            </div>
          </div>
          <div className="right_column">
            <div className="form_container_warp" data-aos="zoom-out">
              <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="input_container">
                  <label className="label" htmlFor="name">
                    Full Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    className="form__field"
                    {...register("name", { required: true, maxLength: 20 })}
                    name="name"
                  />
                  {errors.name && <span id="error">This field is required</span>}
                </div>
                <div className="input_container">
                  <label className="label" htmlFor="email">
                    E-Mail Address
                  </label>
                  <input
                    id="email"
                    type="email"
                    className="form__field"
                    {...register("email", { required: true })}
                    name="email"
                  />
                  {errors.email && <span id="error">This field is required</span>}
                </div>
                <div className="input_container">
                  <label className="label" htmlFor="phone_number">
                    Mobile Number
                  </label>
                  <input
                    id="phone_number"
                    type="text"
                    className="form__field"
                    {...register("phone_number", { required: true, maxLength: 10 })}
                    name="phone_number"
                  />
                  {errors.phone_number && <span id="error">This field is required</span>}
                </div>
                <div className="input_container">
                  <label className="label" htmlFor="message">
                    Enter Your Message
                  </label>
                  <textarea
                    id="message"
                    className="text_area"
                    {...register("message", { required: true, maxLength: 200 })}
                    name="message"
                  />
                  {errors.message && <span id="error">This field is required</span>}
                </div>
                <div className="recapt">
                  <Recaptcha
                    sitekey="6Lch-jwdAAAAAPFKnWDTb4uhUdlzb1aZ3EGyEwD5"
                    render="explicit"
                    size="normal"
                    onloadCallback={callback}
                    verifyCallback={verifycallback}
                  />
                </div>
                <div className="button_position">
                  <button className="submit" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* Contact_us section ends */}

      {/* {sessionStorage.getItem("accepted") ?

                <div id="simple-cookie-consent">
                    <div className="cookie-consent-container">
                        <div className="cookie-consent-notice">
                            <h4>Your Cookie Preferences</h4>
                            <div>
                                <p>This website uses cookies to give our users the best experience. You can manage your settings below or find out by reading our <a onClick={route_to_cookies}>Cookie Policy</a>.</p>
                            </div>
                        </div>
                        <div className="cookie-consent-selection">
                            <button value="true" className="cookie-consent-allow" onClick={hide_cookies}>Allow All Cookies</button>
                        </div>
                    </div>
                </div>
                : null
            } */}

      <CookieConsent
        location="bottom"
        buttonText="Allow Cookies"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "18px" }}
        expires={150}
      >
        <div>
          This website uses cookies to give our users the best experience. You
          can manage your settings below or find out by reading our{" "}
          <span
            style={{ color: "green", cursor: "pointer" }}
            onClick={route_to_cookies}
          >
            Cookie Policy
          </span>
          .
        </div>
      </CookieConsent>
    </div>
  );
}

export default Home;
