import React, { Fragment } from "react";
import "./contact.css";
// import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Recaptcha from "react-recaptcha";
import emailjs from "emailjs-com";

// declare global {
//   interface Window {
//     Email: any;
//   }
// }

// let Email = window.Email;

function Contact() {
  var isverified: boolean = false;

  // EMAIL HANDLER

  type Inputs = {
    name: string;
    email: string;
    phone_number: Number;
    message: string;
  };

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const onSubmit = (e: Event) => {
    // e.preventDefault();
    if (sessionStorage.getItem('isverified') === "true") {
        console.log("isverified","true");
        
      emailjs
        .sendForm(
          "service_a14xaya",
          "template_rstupf1",
          "#contactForm",
          "user_elTEvMlSv9RucbDjIdmyf"
        )
        .then(
          (result) => {
            console.log(result.text,"result");
            if(result.text === "OK")
            {
                window.alert("The form as been submitted successfully")
                window.location.reload();
                window.scrollTo(0, 0)
                sessionStorage.removeItem('isverified');
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      alert("Please verify that you are a human");
    }
  };

  const callback = () => {
    console.log("recaptcha working");
  };

  const verifycallback = (response: any) => {
    if (response) {
      console.log("response working");
      // isverified = true;
      sessionStorage.setItem('isverified', "true");
    }
  };

  // const history = useHistory();
  // const route_to_career_form = () => history.push('/career/form');

  return (
    <Fragment>
      <div className="contactpageTitle">CONTACT US</div>
      <div className="contact-container-position">
          <div className="contact_us_subheading">
            Want a free quote to estimate the price of your project ?
          </div>
          <div className="contact_us_content">
            Fill this form and we will get back to you on your requirements if
            feasible
          </div>
          <div className="contact_us_content">Or</div>
          <div className="contact_us_content">
            Reach out to us directly using E-mail
          </div>
          <div id="bold" className="contact_us_content">info@glocalsense.org</div>
        </div>
      
      <div className="body">
          {/* contact us updated code */}
        
        <div className="contactpage_left">
          <div className="form_container_warp">
              
            <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
              <div className="input_container">
                <label className="label" htmlFor="name">
                  Full Name
                </label>
                <input
                  id="name"
                  type="text"
                  className="form__field"
                  {...register("name", { required: true })}
                  name="name"
                />
                {errors.name && <span id="error">This field is required</span>}
              </div>
              <div className="input_container">
                <label className="label" htmlFor="email">
                  E-Mail Address
                </label>
                <input
                  id="email"
                  type="email"
                  className="form__field"
                  {...register("email", { required: true })}
                  name="email"
                />
                {errors.email && <span id="error">This field is required</span>}
              </div>
              <div className="input_container">
                <label className="label" htmlFor="phone_number">
                  Mobile Number
                </label>
                <input
                  id="phone_number"
                  type="text"
                  className="form__field"
                  {...register("phone_number", { required: true, maxLength: 10 })}
                  name="phone_number"
                />
                {errors.phone_number && <span id="error">This field is required</span>}
              </div>
              <div className="input_container">
                <label className="label" htmlFor="message">
                  Enter Your Message
                </label>
                <textarea
                  id="message"
                  className="text_area"
                  {...register("message", { required: true, maxLength: 200 })}
                  name="message"
                />
                {errors.message && <span id="error">This field is required</span>}
              </div>
              <div className="recapt">
                <Recaptcha
                  sitekey="6Lch-jwdAAAAAPFKnWDTb4uhUdlzb1aZ3EGyEwD5"
                  render="explicit"
                  onloadCallback={callback}
                  verifyCallback={verifycallback}
                />
              </div>
              <div className="button_position">
                <button className="submit" type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="contactpage_right">
          <div className="contactpagerightUp">
            <div className="contactadressContainer">
              <div className="contactpageaddressWarp">
                <div className="label_">India</div>
                <div className="contact_component">
                  <img src="images/contact-icon/address-black.png" alt="" />
                  <div className="email">
                    158, 2nd floor - Guleacha Towers,
                    <br /> Arcot road, opp to forum Vijaya mall, <br />
                    Vadapalani, <br /> Chennai - 600 026.
                  </div>
                </div>
                {/* <div className="contact_component">
                                <img src="/icons8-phone-50.png" alt=""/>
                                <div className="email">+91 7010725840</div>
                            </div> */}
              </div>

              <div className="contactpageaddressWarp">
                <div className="label_">USA</div>
                <div className="contact_component">
                  <img src="images/contact-icon/address-black.png" alt="" />
                  <div className="email">
                    11990 Long Street,
                    <br /> Apt 2521, Overland Park,
                    <br /> KS 66213.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contactpagerightDown">
            <iframe
              title="googlemap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31092.028000987844!2d80.20086709717988!3d13.06724460786417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266c253ba03f5%3A0x4b2cdc2fdeec8736!2sInspire%20Workspace%2C%20Co-working%2C%20Private%20Office%2C%20Virtual%20Office%20Chennai!5e0!3m2!1sen!2sin!4v1631383697174!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0, padding: "20px" }}
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Contact;
