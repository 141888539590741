import './about.css';
import {useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';



function About() {

    // animation initializing here 

    useEffect(() => {
        AOS.init({ duration: 2000});
    })


    // design started here
    return (
        <div className="about_body">
            <div className="about_content_wrap">
                <div className="about_title" data-aos="fade-in">
                    About Us
                </div>
                <div className="about_main-img" data-aos="fade-in">
                    <img src="images/about-us/about.svg" alt="" />
                </div>
                <div className="about_main_content" data-aos="fade-in">
                    Glocalsense, an epitome of globalisation and localisation, senses and synchronizes with future technological advancements and provides client friendly products and services. Our business model focuses on Business To Individual (BTI). The motto of Glocalsense is to offer solutions in the long run to complex problems faced by the client using meticulously planned cutting edge solutions.
                    Our Core Expertise at GlocalSense includes Creative Product Development,Website development, Digital Marketing services and Mobile application development. Our portfolio includes custom Software Development, Website Designing, Website Development, IT Consulting, Staffing, Digital Marketing, E-Business Solutions, Content Management and Back Office Support. We have our presence in locations around the globe including India, Germany and USA.
                </div>
                <div className="about_box" data-aos="fade-left">
                    <div className="POABL">
                        To endeavor a quality and best IT Solution for diverse range of business industries from small to big corporation.
                    </div>
                    <div className="POABR">
                        <div className="about_us_box">
                            <img src="images/about-us/about-1.jpeg" alt="" />
                        </div>
                    </div>
                </div>

                <div className="about_box" data-aos="fade-right">
                    <div className="POABL">
                        To expand our comprehensive service seamlessly to wide range of customers globally.
                    </div>
                    <div className="POABR">
                        <div className="about_us_box">
                        <img src="images/about-us/about-2.jpeg" alt="" />
                        </div>
                    </div>
                </div>

                <div className="about_box" data-aos="fade-left">
                    <div className="POABL">
                        The Growth of customer satisfaction and the change in Global Market has made us innovative.
                    </div>
                    <div className="POABR">
                        <div className="about_us_box">
                        <img src="images/about-us/about-3.jpeg" alt="" />
                        </div>
                    </div>
                </div>

                <div className="about_box" data-aos="fade-left">
                    <div className="POABL">
                        With years of experience in development, We deliver reliable solutions to our customers for client challenges.
                    </div>
                    <div className="POABR">
                        <div className="about_us_box">
                        <img src="images/about-us/about-4.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
