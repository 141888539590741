import React from 'react';
import './webDevelopment.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

function WebDevelopment() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    })

    return (
        <div className="webdev_main_container">
            <div className="webdev_title_container">
                <div className="webdev_title_sub_container" data-aos="fade-in">
                    {/* <img src={webDevelopment} alt=""></img> */}
                    <div className="webdev_title">Web Development</div>
                </div>
            </div>
            <div className="web_middle_content_container">
                    <img src="images/web-development/webdev_main_content.svg" alt="" data-aos="fade-in"></img>
                <div className="web_middle_container_content">

                    <div className="container_content" id="wordpress">

                        <div className="webdev_subtitle_container" data-aos="fade-in">
                            <img src="images/web-development/wordpress.svg" alt=""></img>
                            <div className="webdev_subtitle">WordPress Website</div>
                        </div>
                        <div className="webdev_subtitle_content" data-aos="fade-in">
                            WordPress Web Development is a free and open-source platform that is used for the content management system. This platform features plugin architecture and an inbuilt template system.
                            WordPress for business requires a lot of effort followed by continuous maintenance and update. Also, it mandates a lot of patience, hard work, and dedication and this, in turn, would definitely reward you with better results.
                            Are you planning to start a WordPress web design for your business? We at Glocalsense help you with managing your WordPress account and provide you in the creation of all necessary content, blogs, articles, and much more.</div>
                    </div>

                    <div className="container_content" id="ecommerce">
                        <div className="webdev_subtitle_container" data-aos="fade-in">
                            <img src="images/web-development/ecommerce.svg" alt=""></img>
                            <div className="webdev_subtitle">E-Commerce Website</div>
                        </div>
                        <div className="webdev_subtitle_content" data-aos="fade-in">
                            E-Commerce is the latest marketing technique that is currently trending. Here business organizations list out their services and products and make it available 24/7 for the people.
                            Having an E-Commerce site for your business would help you to grow as a brand and reach your audience easily. Your products and services would be available for your audience round the clock. It is a better option to reach out to your audience and grab their attention.
                            Planning to own an e-commerce site for your business? Contact our professionals today. We at Glocalsense help you to create your own e-commerce platform based on your requirements and list out your products and services.</div>
                    </div>
                    <div className="container_content">
                        <div className="webdev_subtitle_container" data-aos="fade-in">
                            <img src="images/web-development/business.svg" alt=""></img>
                            <div className="webdev_subtitle">Business Website</div>
                        </div>
                        <div className="webdev_subtitle_content" data-aos="fade-in">A business website serves as a medium to connect with your audience, keep them engaged, provide services to your customers 24/7, and much more. Basically, a website serves a front-face for your business and helps to reach your audience easily.
                            Business website development is a process where a business organization develops an official website for their business – to tell their audience about them, the services and products offered, testimonials, contact details, and much more. Having a website for your business makes you more reliable and builds trust with your audience.
                            Looking for the right web solutions company? We at Glocalsense can help you create your own business website that is interactive and visually appealing.</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebDevelopment;
