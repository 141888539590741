import "./PP.css"

function PP() {


    return (
        <section>
            <div className="text-warp">

                <div className="TACHeading">
                    Privacy policy
                </div>
                <div className="TACPara">
                    Effective Date: September 01, 2021.
                </div>
                <div className="TACPara">
                    GLOCALSENSE BUSINESS SOLUTIONS LLP and its subsidiaries and affiliates (collectively, “Glocalsense”, “us” or “we”) understand that your privacy is important to you. We are committed to respecting your privacy and protecting your personal data, which is any information that is capable of identifying you as an individual person. This Privacy Policy describes how we handle and protect your personal data in connection with Glocalsense's business activities, like conferences and events, and client services, and on websites, applications, and communications that post a link to this Privacy Policy (collectively, “the Sites”), in our capacity as data controllers. Please see our Terms of use for more information about our online terms and policies in general.
                </div>

                <div className="TACHeading">
                    Information we collect
                </div>
                <div className="TACPara">
                    Glocalsense collects personal data in the course of its business activities, including in connection with some client services. We also collect personal data on certain areas of the Sites when you register for or apply for jobs through the Glocalsense Recruitment Page, create a user profile, register for Glocalsense newsletters and alerts, Website, sign up for conferences and events, and/or participate in public user posting areas (such as bulletin boards, discussion forums and surveys). The personal data collected varies and may include information that you provide, such as your name, mailing address, e-mail address, telephone number, fax number, and background information required to apply for a job.
                </div>
                <div className="TACPara">
                    We may also automatically collect information about the devices you use to interact with our Sites. The information we automatically collect may include IP address, device identifier, web browser, and browsing information collected through cookies, web beacons, pixels, clear gifs, and other similar technologies (collectively “Cookies and Other Tracking Technologies”) on our Sites. We may also automatically collect information about how you use the Sites, such as what you have searched for and viewed on the Sites. The information automatically collected may be associated with a unique identifier as well as with any other personal data you have provided.
                </div>
                <div className="TACPara">
                    Glocalsense may also collect personal data about you from third parties, such as data brokers or aggregators, in the course of its business activities including in connection with some client services. This includes demographic, professional and other information that is publicly available online, including information you choose to make public through social media platforms and other public online forums. We may combine this data with existing information we have about you or use it independently, for the purposes of our benchmarking and data analytics activities (e.g., analysis of recruiting practices across an industry, detecting fraud patterns in connection with financial transactions, foot traffic in retail environments). We will always seek to confirm that the third party has provided transparent information about its use of this data, including its disclosure to third parties like Glocalsense, in compliance with applicable law.
                </div>

                <div className="TACHeading">
                    Use of information
                </div>
                <div className="TACPara">
                    Glocalsense uses your personal data to fulfill your requests for information, process your requests to participate in conferences and events, personalize content that you view or receive on the Sites, evaluate and improve our services, distribute newsletters and alerts to you, recruit and evaluate job applicants, analyze the Sites’ performance and functioning, publish your letters to the editor or comments, prevent fraud, enforce our terms of use, comply with all applicable laws and corporate reporting obligations, enforce Glocalsense’s agreements, and accomplish other purposes you may initiate or request. In some situations, the collection of personal data may be required for the operation of the Sites, or we may use it in the course of our business activities, including in connection with some client services, for example, to provide certain services or products such as our benchmarking products. We may combine and/or analyze personal data to evaluate and offer content and services most relevant to you. We may keep any of your personal data on file and use it to contact you.
                </div>
                <div className="TACPara">
                    Glocalsense and its service providers may use first and third party Cookies and Other Tracking Technologies, including web beacons, to manage our Sites and our services and collect analytics about how you use them. Glocalsense and its service providers may collect information about whether you open or click any links in the knowledge, research or event communications that we send to you. The information provided throughout this Privacy Policy about cookies also applies to these other tracking technologies. Please refer to our Cookie Policy for more details regarding our use of Cookies and Other Tracking Technologies.
                </div>
                <div className="TACPara">
                    Our Sites do not track you by collecting personal data about your online activities over time and across third party websites or online services. Accordingly, we do not alter our data collection and use practices in response to “do not track” signals transmitted from web browsers.
                </div>

                <div className="TACHeading">
                    The legal basis by which we process your personal data
                </div>
                <div className="TACPara">
                    Our processing of your personal data for the purposes mentioned above is based:
                </div>
                <div className="TACPara">
                    •	in part, on our legitimate interests in promoting and protecting Glocalsense, building and maintaining relationships. recruitment, and providing our services;
                </div>
                <div className="TACPara">
                    •	in part, on your consent, for example if you create a user profile, or register for our newsletters or alerts, or for business purposes that support web operations such as understanding and enhancing the quality of your experience on our web-sites;
                </div>
                <div className="TACPara">
                    •	in part, to comply with the law, when certain information is necessary to satisfy our legal or regulatory obligations.
                </div>

                <div className="TACHeading">
                    Disclosure of personal data: data recipients and international data transfers
                </div>
                <div className="TACPara">
                    Personal data collected in the course of Glocalsense business activities, including in connection with some client services, as well as on the Sites may be transferred from time to time to Glocalsense subsidiaries and affiliates and their personnel across our global organization, as well as to our third party service providers located throughout the world, including in countries where the local law may grant you fewer rights than you have in your own country. Additionally, the Sites may be viewed and hosted by Glocalsense and our third party service providers anywhere in the world. Where required by law, we have put in place legal mechanisms designed to ensure adequate data protection of your personal data that is processed by Glocalsense subsidiaries, affiliates and third party service providers, including the transfer of your personal data to countries other than the one in which you reside. If you would like more information about these legal mechanisms, which may include the EU’s Standard Contractual Clauses, please contact us at the address below. By using any of the Sites and providing information on any of them, you voluntarily consent to such trans-border transfer and hosting of such information.
                </div>
                <div className="TACPara">
                    Glocalsense will not intentionally disclose or transfer (and will take reasonable steps to prevent the unauthorized or accidental disclosure of) your personal data to third parties without your consent or as otherwise permitted by law, whether for such third parties’ own marketing purposes or otherwise, except as follows. Glocalsense may provide access to your personal data to third party service providers engaged by Glocalsense to provide services related to the Sites as well as related to Glocalsense’s business activities, including in connection with some client services, in the manner agreed upon in our client services agreements. We maintain processes designed to ensure that any processing of personal data by third party service providers is consistent with this Privacy Policy and protects the confidentiality, availability, and integrity of your personal data.
                </div>
                <div className="TACPara">
                    We also may share your personal data with third party service providers who perform services and functions on our behalf to support our interactions with you, including, for example, processing recruitment materials, administering surveys or contests, or communicating with you.
                </div>

                <div className="TACPara">
                    In addition, we may disclose information about you:
                </div>
                <div className="TACPara">
                    •	If we are required to do so by law or legal process;
                </div>
                <div className="TACPara">
                    •	To law enforcement authorities or other government officials;
                </div>
                <div className="TACPara">
                    •	When we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity;
                </div>
                <div className="TACPara">
                    •	If disclosure is necessary to protect the vital interests of a person;
                </div>
                <div className="TACPara">
                    •	To enforce our Terms of use;
                </div>
                <div className="TACPara">
                    •	To protect our property, services and legal rights;
                </div>
                <div className="TACPara">
                    •	To prevent fraud against Glocalsense, our subsidiaries, affiliates and/or business partners;
                </div>
                <div className="TACPara">
                    •	To support auditing, compliance, and corporate governance functions; or
                </div>
                <div className="TACPara">
                    •	To comply with any and all applicable laws.
                </div>
                <div className="TACPara">
                    In addition, we may disclose or transfer your personal data in the event of a re-organization, merger, sale, joint venture, assignment, or other transfer or disposition of all or any portion of our business.
                </div>

                <div className="TACHeading">
                    User forums
                </div>
                <div className="TACPara">
                    You should be aware that whenever you publicly disclose information online, that information could be collected and used by others. Glocalsense is not responsible for any action or policies of any third parties who collect information that users publicly disclose in any such forums on the Sites.
                </div>

                <div className="TACHeading">
                    Link to third party sites
                </div>
                <div className="TACPara">
                    Glocalsense may provide links to third party websites or information as a service to our users. If you use these links, you will leave the Sites. Such links do not constitute or imply an endorsement, sponsorship, or recommendation by Glocalsense of the third party, the third party website, or the information contained therein, and Glocalsense shall not be responsible or liable for your use thereof. Such use shall be subject to the terms of use and privacy policies applicable to those sites.
                </div>

                <div className="TACHeading">
                    Social Networking
                </div>
                <div className="TACPara">
                    The Sites may allow you to sign into and associate your social network accounts including, but not limited to, Twitter, LinkedIn, Facebook, YouTube, Pinterest, Medium, Vimeo with Glocalsense. The Sites also may allow you to log in to a Glocalsense account using certain social network account credentials.
                    By associating your social network account with Glocalsense or logging in to a Glocalsense account using your social network account credentials, you give us permission to access information that you have made available in your public profile for that social network account. The information available in your public profile varies based on the social network and your settings, but may include your email address, real name, profile picture, gender, and location. We use the information we receive from your social network account in accordance with the social network’s terms of use and this Privacy Policy. Please refer to the privacy settings in your social network account for information about what data is shared with Glocalsense and other connected applications and to manage the data that is shared through your account, including information about your activities using our Sites.
                    If you would like to disconnect a social media account from us, refer to the settings of that social network account and its provider.
                </div>

                <div className="TACHeading">
                    Security
                </div>
                <div className="TACPara">
                    Glocalsense has implemented generally accepted standards of technology and operational security to protect personal data from loss, misuse, alteration, or destruction. Only authorized Glocalsense personnel and third party service providers are provided access to personal data, and these employees and service providers are required to treat this information as confidential. Despite these precautions however, Glocalsense cannot guarantee that unauthorized persons will not obtain access to your personal data.                
                </div>

                <div className="TACHeading">
                    Data retention
                </div>
                <div className="TACPara">
                    Glocalsense retains personal data, as necessary, for the duration of the relevant business relationship.
                    We may also retain personal data for longer than the duration of the business relationship should we need to retain it to protect ourselves against legal claims, use it for analysis or historical record-keeping, or comply with our information management policies and schedules. If you request that we delete your personal data, Glocalsense will make reasonable attempts to delete all instances of the information in their entirety. For requests for access, corrections, or deletion, please refer to the “Your Rights” section of this Privacy Policy.
                </div>

                <div className="TACHeading">
                    Children
                </div>
                <div className="TACPara">
                    The Sites are not designed for or directed at children 16 years of age or younger, and Glocalsense does not intentionally collect or maintain personal data about anyone under this age.
                </div>

                <div className="TACHeading">
                    Your rights
                </div>
                <div className="TACPara">
                    Where granted by applicable law, you may have the right to request access to the personal data that we have collected about you for the purposes of reviewing, modifying, or requesting deletion of the data. You may also have the right to request a copy of the personal data that we have collected about you and to have any inaccuracies in that data corrected. In certain circumstances, you may also request that we cease processing your personal data.
                    If you would like to make a request to access, review, or correct the personal data we have collected about you, or to discuss how we process your personal data, please contact us at support@glocalsense.org. To help protect your privacy and security, we will take reasonable steps to verify your identity, such as requiring a password and user ID, before granting access to your personal data. We will make reasonable attempts to promptly investigate, comply with, or otherwise respond to your requests as may be required by applicable law. Different laws may prevent us from providing access to your personal data or otherwise fully complying with your request depending upon the circumstances and the request, such as for example, where producing your information may reveal the identity of someone else. We reserve the right to charge an appropriate fee for complying with your request where allowed by applicable law, and/or deny your requests where they may be manifestly unfounded, and/or excessive, or otherwise objectionable or unwarranted under applicable law.
                    In addition, and where granted by local law, you have the legal right to lodge a complaint with a competent data protection authority.
                </div>

                <div className="TACHeading">
                    Unsubscribe
                </div>
                <div className="TACPara">
                    If you have registered for Glocalsense newsletter or alerts, or you receive invitations to survey or events from Glocalsense and you prefer not to receive future email communications from us, please review the communications settings in your profile on www.glocalsense.org, follow the instructions on the page of the Site where you have provided such information, subscribed or registered or write to support@glocalsense.org to unsubscribe from all Glocalsense communications.
                </div>

                <div className="TACHeading">
                    Consent; changes to Privacy Policy
                </div>
                <div className="TACPara">
                    By using the Sites, you consent to the collection, use, and storage of your personal data by us in the manner described in this Privacy Policy and elsewhere on the Sites. We reserve the right to make changes to this Privacy Policy from time to time. We will alert you to any such changes by updating this Privacy Policy. If we make material changes to this Privacy Policy that increase our rights to use personal data that we have previously collected about you, we will obtain your consent either through an email to your registered email address or by prominently posting information about the changes on our Sites.
                </div>

                <div className="TACHeading">
                    Contact us
                </div>
                <div className="TACPara">
                    If you have any questions about our Privacy Policy or if you would like to communicate with our Data Privacy Team, please contact us at:
                </div>
                <div className="TACPara">
                    Regional Office, 
                </div>
                <div className="TACPara">
                    GLOCALSENSE BUSINESS SOLUTIONS LLP, 
                </div>
                <div className="TACPara">
                    #158, 2nd Floor, Gulecha Tower, 
                </div>
                <div className="TACPara">
                    Arcot Road, Vadapalani, 
                </div>
                <div className="TACPara">
                    Chennai – 600 026. TN, India
                </div>
                <div className="TACPara">
                    +91 7010725840
                </div>
                <div className="TACPara">
                    And email us at support@glocalsense.org
                </div>


                

            </div>
        </section>
    );
}

export default PP;
