import React from "react";
import './mobile.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

 function Mobile() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    })
    return (
        <div className="mobile_main_container">
            <div className="mobile_title_container" data-aos="fade-in">
                <div className="mobile_title_sub_container">
                    {/* <img src={mobile} alt=""></img> */}
                    <div className="mobile_title">Application Development</div>
                </div>
            </div>
            <div className="mobile_middle_content_container">
                <img src="images/app-development/mobile_content.svg" alt="" data-aos="fade-in"></img>
                <div className="mobile_middle_container_content">
                    <div className="container_content">
                        <div className="mobile_subtitle_content" data-aos="fade-in">
                            Mobile applications play a crucial role in business as it helps your organization to stay connected with your audience/customer/clients. Applications such as personal assistant apps help in monitoring productivity rates, boosts collaboration among the employees, and much more.
                        </div>
                        <div className="mobile_subtitle_content" data-aos="fade-in">
                            Planning to develop a mobile application for your business? We are here to help you. Our professionals can help you right away by providing necessary solutions, create applications based on your requirements, and help you maintain it.
                            Based on client requirements, we can create different kinds of applications – be it personal assistants, GPS-powered apps, proprietary solutions, games, monitoring apps, mobile educators, and much more.
                        </div>
                        {/* <div className="mobile_img">
                            <img src={Android} alt=""></img>
                            <img src={Ios} alt=""></img>
                        </div> */}
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mobile;