import "./cookies.css"

function Cookies() {


    return (
        <section>
            <div className="text-warp">

                <div className="TACHeading">
                    Cookie Policy
                </div>
                <div className="TACPara">
                    Effective date: September 01, 2021
                </div>
                <div className="TACPara">
                    GLOCALSENSE BUSINESS SOLUTIONS LLP and its subsidiaries and affiliates (collectively, “Glocalsense”, “us” or “we”) understand that your privacy is important to you and are committed to being transparent about the technologies it uses. This Cookie Policy explains how and why cookies, web beacons, pixels, clear gifs, and other similar technologies (collectively “Cookies and Other Tracking Technologies”) may be stored on and accessed from your device when you use or visit any website or app that posts a link to this Policy (collectively, “the Sites”). This Cookie Policy should be read together with our Privacy Policy and our Terms of use.
                </div>
                
                <div className="TACHeading">
                    What are Cookies and Other Tracking Technologies?
                </div>
                <div className="TACPara">
                    A cookie is a small text file that can be stored on and accessed from your device when you visit one of our Sites, to the extent you agree. The other tracking technologies work similarly to cookies and place small data files on your devices or monitor your website activity to enable us to collect information about how you use our Sites. This allows our sites to recognize your device from those of other users of the Sites. The information provided below about cookies also applies to these other tracking technologies. You can find more information at www.allaboutcookies.org and www.youronlinechoices.eu. 
                </div>

                <div className="TACHeading">
                    How do our sites use Cookies and Other Tracking Technologies?
                </div>
                <div className="TACPara">
                    Glocalsense uses Cookies and Other Tracking Technologies to identify you and your interests, to remember your preferences, and to track your use of our Sites. We also use Cookies and Other Tracking Technologies to control access to certain content on our Sites, protect the Sites, and to process any requests that you make of us.
                    To administer our Sites and for research purposes, Glocalsense also has contracted with third party service providers to track and analyze statistical usage and volume information from our Site users. These third party service providers use persistent Cookies to help us to improve the user experience, manage our site content, and analyze how users navigate and utilize the Sites.
                </div>

                <div className="TACHeading">
                    First and Third Party Cookies
                </div>
                <div className="TACPara">
                    First-party Cookies” are cookies that belong to Glocalsense and that Glocalsense places on your device. “Third-party cookies” are cookies that another party places on your device through our Site. Glocalsense may contract with third party service providers to send e-mails to users who have provided us with their contact information. To help measure and improve the effectiveness of our e-mail communications, and/or to determine whether messages have been opened and links clicked on, the third party service providers may place Cookies on the devices of these users.
                    For more information on how these companies collect and use information on our behalf, please refer to their privacy policies as indicated below. Depending on the Site, we use the following types of cookies:
                    Persistent Cookies. We use persistent Cookies to improve your experience of using the Sites. This includes recording your acceptance of our Cookie Policy to remove the cookie message which first appears when you use the Sites.
                    Session Cookies. Session Cookies are temporary and deleted from your machine when your web browser closes. We use session cookies to help us track internet usage as described above.
                    You may refuse to accept browser Cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of the Sites. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Sites.

                    The data collected by the Sites and/or through Cookies that may be placed on your computer will not be kept for longer than is necessary to fulfill the purposes mentioned above. In any event, such information will not be kept for longer than one year.
                </div>

                <div className="TACHeading">
                    Our Cookies are used for the following purposes:
                </div>
                <div className="TACPara">
                    Cookie Type	Purpose
                    Strictly Necessary/ Technical	These Cookies are necessary to allow us to operate our Sites so you may access them as you have requested. These Cookies, for example, let us recognize that you have created an account and have logged into that account to access Site content. They also include Cookies that enable us to remember your previous actions within the same browsing session and secure our Sites.
                    Analytical/ Performance	These Cookies are used by us or third party service providers to analyze how the Sites are used and how they are performing. For example, these Cookies track what pages are most frequently visited, and from what locations our visitors come from. If you subscribe to a newsletter or otherwise register with the Sites, these Cookies may be correlated to you. These Cookies include, for example, Google Analytics cookies.
                    Functionality	These Cookies let us operate the Sites in accordance with the choices you make. These Cookies permit us to "remember" you in-between visits. For instance, we will recognize your user name and remember how you customized the Sites and services, for example by adjusting text size, fonts, languages and other parts of web pages that are alterable, and provide you with the same customizations during future visits.
                    Third Party Advertising	These Cookies collect information about your activities on these and other sites to provide you targeted advertising. We may also allow our third party service providers to use Cookies on the Sites for the same purposes identified above, including collecting information about your online activities over time and across different websites. 
                    The third party service providers that generate these Cookies, such as Adobe, Google, LinkedIn, Twitter and Facebook, have their own privacy policies, and may use their Cookies to target advertising to you on other websites, based on your visit to our Sites.
                </div>

                <div className="TACHeading">
                    How do I refuse or withdraw my consent to the use of Cookies?
                </div>
                <div className="TACPara">
                    If you do not want cookies to be dropped on your device, you can adjust the setting of your Internet browser to reject the setting of all or some cookies and to alert you when a cookie is placed on your device. For further information about how to do so, please refer to your browser ‘help’ / ‘tool’ or ‘edit’ section or see www.allaboutcookies.org. Please note that if you use your browser settings to block all cookies (including strictly necessary Cookies), you may not be able to access or use all or parts or functionalities of our Sites.
                    If you want to remove previously-stored cookies, you can manually delete the cookies at any time. However, this will not prevent the sites from placing further cookies on your device unless and until you adjust your Internet browser setting as described above.
                    For more information on the development of user profiles and the use of targeting/advertising Cookies, please see www.youronlinechoices.eu if you are located in Europe or www.aboutads.info/choices if in the United States.
                </div>

                <div className="TACHeading">
                    How do we use Other Tracking Technologies in our communications?
                </div>
                <div className="TACPara">
                    Glocalsense may collect information about whether you open or click any links in the knowledge, research or event communications that we send to you through web beacons.       
                    A web beacon is a graphic image, which is placed on a website or in an email that alone or in conjunction with cookies compile information about your usage of or your interaction with a Glocalsense website or email. For example, we may add web beacons to the knowledge, research or event communications that we send to you to determine whether you have opened our email or clicked a link. The analysis gathered helps us improve the effectiveness of content and format of our websites and email communications.
                </div>

                <div className="TACHeading">
                    How do I refuse or withdraw my consent to the use of Web Beacons?
                </div>
                <div className="TACPara">
                    You may avoid web beacons by configuring your email reader program to disable the functionality that enables remote images to load and by refraining from clicking on any links in email messages.
                </div>

                <div className="TACHeading">
                    Contact us
                </div>
                <div className="TACPara">
                    If you have any questions about our Privacy Policy or if you would like to communicate with our Data Privacy Team, please contact us at:
                </div>
                <div className="TACPara">
                    Regional Office, 
                </div>
                <div className="TACPara">
                    GLOCALSENSE BUSINESS SOLUTIONS LLP, 
                </div>
                <div className="TACPara">
                    #158, 2nd Floor, Gulecha Tower, 
                </div>
                <div className="TACPara">
                    Arcot Road, Vadapalani, 
                </div>
                <div className="TACPara">
                    Chennai – 600 026. TN, India
                </div>
                <div className="TACPara">
                    +91 7010725840
                </div>
                <div className="TACPara">
                    And email us at support@glocalsense.org
                </div>



            </div>
        </section>
    );
}

export default Cookies;
