import React from 'react';
import './career.css';
import { useHistory } from 'react-router-dom';


function Career({setSelectedName}:{setSelectedName:any}) {

    const history = useHistory();
    const route_to_career_form = (name:string) => 
    {
        console.log(name);
        setSelectedName(name)
        sessionStorage.setItem('career', name)
        history.push('/career/form');
    }

    return (
        <div className="career_main_container">
            <div className="career_title_container">
                <div className="career_title">Careers</div>
            </div>
            <div className="career_content">
                Apply using the form below or share your recent updated resume to <span id="bold">info@glocalsense.org</span> with position you are applying for entered in the Mail Subject
            </div>
            {/* <div className="email_container">
                    <img src="/black_mail.png" alt=""/>
                    <div className="email">info@glocalsense.org</div>
            </div> */}
            <div className="career_box_container">

                <div className="career_box">
                    <div className="LPOB">
                        <div className="career_box_title">Visual Designer</div>
                        <div className="career_box_subheading">Experience : 0-2 years</div>
                    </div>
                    <div className="RPOB">
                        <button onClick={() => route_to_career_form('Visual Designer')}>APPLY</button>
                    </div>
                </div>

                <div className="career_box">
                    <div className="LPOB">
                        <div className="career_box_title">Copy Writer</div>
                        <div className="career_box_subheading">Experience : 0-2 years</div>
                    </div>
                    <div className="RPOB">
                        <button onClick={() => route_to_career_form('Copy Writer')}>APPLY</button>
                    </div>
                </div>

                <div className="career_box">
                    <div className="LPOB">
                        <div className="career_box_title">Market Researcher</div>
                        <div className="career_box_subheading">Experience : 0-2 years</div>
                    </div>
                    <div className="RPOB">
                        <button onClick={() => route_to_career_form('Market Researcher')}>APPLY</button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Career;
